/**
=========================================================
* Soft UI Dashboard PRO React - v4.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { forwardRef, Component } from "react";

// prop-types is a library for typechecking of props
import PropTypes from "prop-types";

// react-select components
import Select from "react-select";

// Soft UI Dashboard PRO React base styles
import colors from "@/assets/theme/base/colors";

// Custom styles for SoftSelect
import styles from "@/components/SoftSelect/styles";
import { dark } from "@mui/material/styles/createPalette";

import List from 'rc-virtual-list';
import { FixedSizeList } from "react-window";

const height = 35;

class MenuList extends Component {
  render() {
    const { options, children, maxHeight, getValue } = this.props;
    const [value] = getValue();
    const initialOffset = options.indexOf(value) * height;

    return (
      <FixedSizeList
        height={maxHeight}
        itemCount={children.length}
        itemSize={height}
        initialScrollOffset={initialOffset}
      >
        {({ index, style }) => <div style={style}>{children[index]}</div>}
      </FixedSizeList>
    );
  }
}


class MenuList1 extends Component {
  render() {
    const { options, children, maxHeight, getValue } = this.props;
    const [value] = getValue();
    const initialOffset = options.indexOf(value) * height;

    return (

      <List style={{ transition: '1s ease' }} data={children} itemKey="id">

      {(item, ind) =>
        <div style={style}>{children[index]}</div>
    }

  </List>

     
    );
  }
}

const SoftSelect = forwardRef(({ size, error, success, large, placeholder , ...rest }, ref) => {
  const { light , dark } = colors;

  return (
    <Select 
    components={{ MenuList1 }}
      {...rest}
      noOptionsMessage={() => 'Καμία επιλογή'}
      ref={ref}
      placeholder={placeholder ? placeholder : 'Επιλογή'}
      large={large}
      styles={styles(size, error, success )}
      theme={(theme) => ({
        ...theme,
        colors: {
          ...theme.colors,
          primary25: dark,
          primary: dark,
        },
      })}
    />
  )
})

// Setting default values for the props of SoftSelect
SoftSelect.defaultProps = {
  size: "medium",
  error: false,
  success: false,
 
};

// Typechecking props for the SoftSelect
SoftSelect.propTypes = {
  size: PropTypes.oneOf(["small", "medium", "large"]),
  error: PropTypes.bool,
  success: PropTypes.bool,
  placeholder : PropTypes.string
};

export default SoftSelect;
