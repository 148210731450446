import * as React from 'react';

import {
    Card,
    Grid,
    ThemeProvider,
    createTheme
} from "@mui/material";
import {
    CircleMarker,
    MapContainer,
    Marker,
    TileLayer,
    useMapEvents,
    Popup, LayerGroup,LayersControl
} from "react-leaflet";
import loadable from '@loadable/component'
import { useEventContext } from "@/context/EventContext";
import SearchIcon from "@mui/icons-material/Search";
import { useEffect, useState } from "react";
import MarkerClusterGroup from "react-leaflet-cluster";
// @ Theme
//import ProodosDrasewn from "@/Pages/HomepageComponents/ProodosDrasewn";
import breakpoints from '@/assets/theme/base/breakpoints';
import borders from "@/assets/theme/base/borders";
import boxShadows from "@/assets/theme/base/boxShadows";
import colors from "@/assets/theme/base/colors";
import SoftButton from '@/components/SoftButton';
import SoftSelect from "@/components/SoftSelect";
import { setDrawer, setEvent, setSearch, useSoftUIController } from "@/context";
import StyledSoftInputSearch from "@/examples/Tables/DataTableCustom/SoftInputSearchStyle";
import useWindowSize from "@/hooks/useWindowSize";
//import EventDrawer from "../EventDrawer";

// @ MUI DIALOG Imports
import CloseIcon from '@mui/icons-material/Close';
import Dialog from '@mui/material/Dialog';
import IconButton from '@mui/material/IconButton';
import Slide from '@mui/material/Slide';
import shadows from '@mui/material/styles/shadows';
import { TransitionProps } from '@mui/material/transitions';

// @MUI BACKDROP Imports
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import SoftBox from '@/components/SoftBox';

const EventDrawer = loadable(() => import('../EventDrawer'))
const ProodosDrasewn = loadable(() => import('../../HomepageComponents/ProodosDrasewn'))

const Transition = React.forwardRef(function Transition(
    props: TransitionProps & {
        children: React.ReactElement;
    },
    ref: React.Ref<unknown>,
) {
    return <Slide direction="up" ref={ref} {...props} />;
});

interface Value {
    value: string;
    label: string;
}

export default function Events() {
    const queryParams = new URLSearchParams(window.location.search);
    const [hoveredIndex, setHoveredIndex] = useState(null);
    const [controller, dispatch] = useSoftUIController();
    const { search, logo } = controller
    const screenWidth = useWindowSize()

    ///// Dialog /////

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };
    ///// Dialog /////


    const position = [39.074208, 21.824312];
    const {
        setEventsForMap,
        getLatLngBounds,
        categoriesOptions,
        typesOptions,
        periferiakesEnotitesOptions,
        selectedEvents,
        setPeriferiakiEnotita,
        periferiakiEnotita,
        category,
        setCategory,
        setType,
        type,
        center,
        showFilters,
        setMapView,
        mapView,
        setFly,
        initLoad,
        setInitLoad,
        loading,
        setLoading,
        open,
        setOpen,
        isMobile
        // travelToCenter
    } = useEventContext();



    const [newCenter, setNewCenter] = useState(); // Initialize the 'map' state
    const katalimaType = type;
    const eventType = queryParams.get('type');
    const citizenType = queryParams.get('citizen');


    useEffect(() => {

        if (!loading && !initLoad) {
            //   getCloserEvents(eventType, periferiakiEnotita, katalimaType, category)
        }


    }, [loading])

    function LocationMarker() {
        const map = useMapEvents({

            movestart(e) {
                setInitLoad(false);
                //setLoading(true)
            },

            moveend(e) {
                setLoading(true)
                getCloserEvents(eventType, periferiakiEnotita, katalimaType, category,citizenType)
                //setLoading(false)
            },

        });

        //if (!mapView) {
        setMapView(map)

        //}
    }


    const getCloserEvents = async (eventType, periferiakiEnotita, katalimaType, category,citizenType) => {

        var queryString;
        if (loading) {
            return;
        }

        if (!isMobile || (isMobile && open)) {
            queryString = new URLSearchParams({
                latitude: mapView ? mapView?.getCenter()?.lat : center[0],
                longitude: mapView ? mapView?.getCenter()?.lng : center[1],
                eventType: eventType,
                periferiakiEnotita: periferiakiEnotita,
                katalimaType: katalimaType,
                category: category,
                citizen: citizenType
                // ... other data
            }).toString();

        } else {
            queryString = new URLSearchParams({

                eventType: eventType,
                periferiakiEnotita: periferiakiEnotita,
                katalimaType: katalimaType,
                category: category,
                citizen:citizenType
                // ... other data
            }).toString();
        }
        // console.log(periferiakiEnotita)
        const url = `/?${queryString}`;

        try {
            const response = await fetch(url, {
                method: "GET",
                headers: {
                    Accept: "application/json", // Accept JSON response
                },
            });

            if (response.ok) {
                const data = await response.json();

                setEventsForMap(data.events)

            } else {
                console.error("Error:", response.statusText);
            }
        } catch (error) {
            console.error("Error:", error);

        } finally {
            setLoading(false)

        }
    };

    /*const getCloserEvents = async (eventType, periferiakiEnotita, katalimaType, category) => {

        const queryString = new URLSearchParams({
            latitude: mapView?.getCenter()?.lat ?? center[0],
            longitude: mapView?.getCenter()?.lng ?? center[1],
            eventType:eventType,
            periferiakiEnotita:periferiakiEnotita,
            katalimaType: katalimaType,
            category: category
            // ... other data
        }).toString();
        // console.log(periferiakiEnotita)
        const url = `/?${queryString}`;

        try {

            AxiosService.get(url, {
                method: "GET",
                headers: {
                    Accept: "application/json", // Accept JSON response
                },
            })
            .then(response => {

                //setLoading(false)
                setResponseData(response?.data?.events)
            })



        } catch (error) {
            console.error("Error:", error);
        }
    };*/



    const handleOpenDrawer = (ev: Object) => {
        setEvent(dispatch, ev);
        setDrawer(dispatch, true);
    };

    const handleType = (value: Value) => {
        setLoading(screenWidth <= breakpoints.values.sm && true)
        setType(value.value);
        getCloserEvents(eventType, periferiakiEnotita, value.value, category,citizenType);
        setFly(true)
    };


    const handleCategory = (value: Value) => {
        setLoading(screenWidth <= breakpoints.values.sm && true)
        setCategory(value.value);
        getCloserEvents(eventType, periferiakiEnotita, katalimaType, value.value,citizenType)
        setFly(true)

    };

    // Inside your handleEnotita function:

    const handleEnotita = (value: Value) => {
        setLoading(screenWidth <= breakpoints.values.sm && true)
        setPeriferiakiEnotita(value.value)
        getCloserEvents(eventType, value.value, katalimaType, category,citizenType)
        setFly(true)
    };


    const onSearchChange = (value) => {
        value =
            value && typeof value != "number"
                ? value
                    .toLowerCase()
                    .normalize("NFD")
                    .replace(/[\u0300-\u036f]/g, "")
                : "";
        setSearch(dispatch, value);
    };

    const theme = createTheme({
        components: {
            MuiCard: {
                styleOverrides: {
                    root: {
                        boxShadow: boxShadows.custom,
                        borderRadius: 25,
                        height: "100%",
                        margin: 10,
                    },
                },
            },
            MuiSvgIcon: {
                styleOverrides: {
                    root: {
                        background: colors.gradients.custom,
                        color: colors.white.main,
                        padding: 6,
                        width: 40,
                        height: 40,
                        borderRadius: 5,
                    },
                },
            },
            MuiTypography: {
                styleOverrides: {
                    root: {
                        whiteSpace: "nowrap!important",
                    },
                },
            },
        },
    });

    return (
        <ThemeProvider theme={theme}>
            <Card>
                <Grid container p={3} pt={6}>
                    <Grid item xs={12} mb={3}>
                        <Grid container spacing={3}>
                            <Grid item xs={12} md={3}>
                                <EventDrawer />
                                <SoftSelect
                                    placeholder="Περ. Ενότητα"
                                    options={periferiakesEnotitesOptions}
                                    onChange={handleEnotita}
                                />
                            </Grid>

                            {showFilters && <Grid item xs={12} md={3}>
                                <SoftSelect
                                    placeholder="Τύπος Καταλύματος"
                                    options={typesOptions}
                                    onChange={(value: Value) => handleType(value)}
                                />
                            </Grid>}

                            {showFilters && <Grid item xs={12} md={3}>
                                <SoftSelect
                                    placeholder="Κατηγορία Καταλύματος"
                                    options={categoriesOptions}
                                    onChange={handleCategory}
                                />
                            </Grid>}

                            {screenWidth <= 1200 && (
                                <Grid item xs={12}>
                                    <Grid container>
                                        <Grid
                                            sx={{
                                                boxShadow: boxShadows.custom,
                                                borderRadius:
                                                    borders.borderRadius.md,
                                            }}
                                            item
                                            xs={12}
                                            md={6}
                                        >
                                            <StyledSoftInputSearch
                                                placeholder="Αναζήτηση"
                                                value={search}
                                                onChange={({
                                                    currentTarget,
                                                }) => {
                                                    setSearch(dispatch, search);
                                                    onSearchChange(
                                                        currentTarget.value
                                                    );
                                                }}
                                                icon={<SearchIcon />}
                                                size="small"
                                            />
                                        </Grid>
                                    </Grid>
                                </Grid>
                            )}

                            {isMobile &&
                                <Grid item xs={12}>
                                    <Grid container>
                                        <Grid
                                            sx={{
                                                boxShadow: boxShadows.custom,
                                                borderRadius:
                                                    borders.borderRadius.md,
                                            }}
                                            item
                                            xs={12}
                                            md={6}
                                        >
                                            <SoftButton onClick={handleClickOpen}>
                                                Χάρτης Τοποθεσιών
                                            </SoftButton>
                                        </Grid>
                                    </Grid>
                                </Grid>

                            }
                        </Grid>
                    </Grid>

                    <Grid zIndex={0} item xs={12}>
                        <Grid container spacing={3}>
                            <Grid item xs={12} lg={4}>
                                <ProodosDrasewn loading={loading} />

                            </Grid>
                            <Grid item xs={12} lg={8}>

                                {!isMobile &&
                                    <MapContainer
                                        center={center}
                                        bounds={getLatLngBounds()}
                                        style={{
                                            height: "70vh",
                                            width: "100%",
                                            borderRadius: borders.borderRadius.sm,
                                        }}
                                        zoom={7}
                                        scrollWheelZoom={true}
                                        
                                    >
                                        
                                        <LayersControl position="topright">
                                            
                                            <LayersControl.BaseLayer checked name="Δρόμοι">
                                                <LayerGroup>
                                               
                                                    <TileLayer url="https://www.google.cn/maps/vt?lyrs=m@189&gl=cn&x={x}&y={y}&z={z}" />
                                                </LayerGroup>


                                            </LayersControl.BaseLayer>
                                          
                                            <LayersControl.BaseLayer name="Δορυφόρος">
                                                <LayerGroup>
                                                    <TileLayer
                                                      attribution="Google Maps Satellites"
                                                      url="https://server.arcgisonline.com/ArcGIS/rest/services/World_Imagery/MapServer/tile/{z}/{y}/{x}"
                                                    />
                                                    <TileLayer url="https://www.google.cn/maps/vt?lyrs=y@189&gl=cn&x={x}&y={y}&z={z}" />
                                                </LayerGroup>

                                            </LayersControl.BaseLayer>

                                        </LayersControl>
                                        
                                         <MarkerClusterGroup>
                                            {selectedEvents?.map(
                                                (position, idx) => (
                                                    <Marker

                                                        eventHandlers={{
                                                            click: (e) => {
                                                                handleOpenDrawer(
                                                                    position
                                                                );
                                                            },
                                                            mouseover: (event) => event.target.openPopup(),
                                                            mouseout: (event) => event.target.closePopup()
                                                        }}
                                                        key={position.id + 'idx'}
                                                        position={{
                                                            lat: position.latitude,
                                                            lng: position.longitude,
                                                        }}
                                                    >
                                                        {/* <Tooltip>{position.name}</Tooltip>
                                            <Popup>{position.description}</Popup> */}
                                                        {hoveredIndex ===
                                                            position.id && (
                                                                <CircleMarker
                                                                    center={{
                                                                        lat: position.latitude,
                                                                        lng: position.longitude,
                                                                    }}
                                                                    radius={8}
                                                                    color={
                                                                        colors.white
                                                                            .main
                                                                    }
                                                                    fillOpacity={0.5}
                                                                />
                                                            )}
                                                        <Popup>
                                                            {position?.title}
                                                        </Popup>
                                                    </Marker>
                                                )
                                            )}
                                        </MarkerClusterGroup>
                                        <LocationMarker />
                                    </MapContainer>
                                }
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Card>



            {isMobile &&
                <>
                    {/* <Backdrop
                        sx={{ color: '#fff', zIndex: 10000, }}
                        open={loading}
                    >
                        <CircularProgress color="inherit" />

                    </Backdrop> */}

                    <Dialog
                        fullScreen
                        open={open}
                        onClose={handleClose}
                        TransitionComponent={Transition}
                    >



                        <IconButton
                            edge="start"
                            color="inherit"
                            onClick={handleClose}
                            aria-label="close"
                            sx={{
                                position: 'absolute',
                                zIndex: 401,
                                right: '5%',
                                top: '1%',
                                'svg': {
                                    boxShadow: shadows[15]
                                }
                            }}
                        >
                            <CloseIcon />
                        </IconButton>
                        <MapContainer
                            center={center}
                            bounds={getLatLngBounds()}
                            style={{
                                height: "100%",
                                width: "100%",
                                borderRadius: borders.borderRadius.sm,
                            }}
                            zoom={7}
                            scrollWheelZoom={true}
                        >



                            {/*<TileLayer url="https://server.arcgisonline.com/ArcGIS/rest/services/World_Imagery/MapServer/tile/{z}/{y}/{x}" />*/}
                            <LayersControl position="bottomright">
                                            
                                <LayersControl.BaseLayer checked name="Δρόμοι">
                                    <LayerGroup>
                                   
                                        <TileLayer url="https://www.google.cn/maps/vt?lyrs=m@189&gl=cn&x={x}&y={y}&z={z}" />
                                    </LayerGroup>


                                </LayersControl.BaseLayer>
                                
                                <LayersControl.BaseLayer name="Δορυφόρος">
                                    <LayerGroup>
                                        <TileLayer
                                          attribution="Google Maps Satellites"
                                          url="https://server.arcgisonline.com/ArcGIS/rest/services/World_Imagery/MapServer/tile/{z}/{y}/{x}"
                                        />
                                        <TileLayer url="https://www.google.cn/maps/vt?lyrs=y@189&gl=cn&x={x}&y={y}&z={z}" />
                                    </LayerGroup>

                                </LayersControl.BaseLayer>

                            </LayersControl>
                                        
                            <MarkerClusterGroup>
                                {selectedEvents?.map(
                                    (position, idx) => (
                                        <Marker

                                            eventHandlers={{
                                                click: (e) => {
                                                    handleOpenDrawer(
                                                        position
                                                    );
                                                },
                                                mouseover: (event) => event.target.openPopup(),
                                                mouseout: (event) => event.target.closePopup()
                                            }}
                                            key={position.id + 'idx'}
                                            position={{
                                                lat: position.latitude,
                                                lng: position.longitude,
                                            }}
                                        >
                                            {/* <Tooltip>{position.name}</Tooltip>
                                            <Popup>{position.description}</Popup> */}
                                            {hoveredIndex ===
                                                position.id && (
                                                    <CircleMarker
                                                        center={{
                                                            lat: position.latitude,
                                                            lng: position.longitude,
                                                        }}
                                                        radius={8}
                                                        color={
                                                            colors.white
                                                                .main
                                                        }
                                                        fillOpacity={0.5}
                                                    />
                                                )}
                                            <Popup>
                                                {position?.title}
                                            </Popup>
                                        </Marker>
                                    )
                                )}
                            </MarkerClusterGroup>
                            <LocationMarker />
                        </MapContainer>

                    </Dialog>
                </>}
        </ThemeProvider>
    );
}
